<script setup lang="ts">
const isSidebarOpen = ref(false);

const toggleSidebar = () => (isSidebarOpen.value = !isSidebarOpen.value);
</script>

<template>
  <UiContainer
    :no-padding="true"
    class="bg-navy text-white"
  >
    <header>
      <div class="container mx-auto flex items-center justify-between py-6">
        <div
          class="flex w-full items-center justify-between space-x-4 xl:w-4/5"
        >
          <!-- Logo -->
          <div class="flex h-10">
            <a
              href="/"
              class="absolute left-1/2 w-[125px] -translate-x-1/2 text-nowrap font-bold text-white lg:relative lg:left-0 lg:translate-x-0"
            >
              <NuxtIcon
                name="logo"
                class="logo text-white"
              />
            </a>

            <div class="search relative ml-2 hidden p-1 xl:ml-10">
              <i
                class="bi bi-search cursor-pointer hover:text-white"
              />
            </div>
          </div>
          <div class="lg:hidden">
            <button
              class="text-white focus:outline-none"
              @click="toggleSidebar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="size-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <!-- Navigation Links -->
          <nav class="hidden bg-navy pr-4 lg:flex lg:space-x-6 xl:space-x-8 xl:pr-40">
            <a
              href="/membership"
              class="btn--link text-white"
            >Membership</a>
            <a
              href="/policy"
              class="btn--link text-white"
            >Policy</a>
            <a
              href="/research"
              class="btn--link text-white"
            >Research</a>
            <a
              href="/education"
              class="btn--link text-white"
            >Education</a>
            <a
              href="/events-and-networking"
              class="btn--link text-white"
            >Events & Networking</a>
            <a
              href="/directories"
              class="btn--link text-white"
            >Directories</a>
          </nav>
        </div>
        <!-- Right Side Buttons -->
        <div class="hidden items-center justify-end space-x-4 lg:flex xl:w-1/5">
          <a
            href="#"
            class="mx-6 hidden hover:text-white"
          >Login</a>
          <a
            href="/join"
            class="group flex w-[140px] items-center justify-center text-nowrap rounded-lg bg-blue py-3 text-white transition-all hover:bg-blue/70"
          >
            Join Us
            <NuxtIcon
              name="arrow-right"
              class="relative right-0 ms-4 text-white transition-all group-hover:-right-2"
            />
          </a>
        </div>
      </div>
      <!-- Sidebar Menu -->
      <div
        class="fixed inset-y-0 z-20 w-[300px] bg-navy p-4 pt-8 shadow-lg transition-all duration-300"
        :class="isSidebarOpen ? 'left-0' : '-left-[300px]'"
      >
        <button
          class="absolute right-3 top-3 mb-4 hover:text-white focus:outline-none"
          @click="toggleSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <nav class="flex flex-col">
          <a
            href="/membership"
            class="py-2 hover:text-white"
          >Membership</a>
          <a
            href="/policy"
            class="py-2 hover:text-white"
          >Policy</a>
          <a
            href="/research"
            class="py-2 hover:text-white"
          >Research</a>
          <a
            href="/education"
            class="py-2 hover:text-white"
          >Education</a>
          <a
            href="/events-and-networking"
            class="py-2 hover:text-white"
          >Events & Networking</a>
          <a
            href="/directories"
            class="py-2 hover:text-white"
          >Directories</a>
        </nav>
      </div>
    </header>
  </UiContainer>
</template>

<style lang="scss" scoped>
  nav a {
    background-size: 0% 1px;

    &:hover,
    &:focus {
      background-size: 100% 1px;
    }
  }

  :deep(.logo svg) {
    @apply w-[100%] h-[100%];
  }
</style>
