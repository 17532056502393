<script lang="ts" setup>
const props = defineProps<{
  cardContainer?: boolean;
  noPadding?: boolean;
}>();

const sectionClass = computed(() => ({
  "py-10 md:py-12 lg:py-20": !props.noPadding && !props.cardContainer, // default padding
  "py-0": props.noPadding, // no padding
  "py-0 md:py-12 lg:py-20": props.cardContainer && !props.noPadding, // card padding
}));

const containerClass = computed(() => ({
  "mx-auto px-6": !props.cardContainer,
  "md:px-6": props.cardContainer,
}));
</script>

<template>
  <section
    class=""
    :class="sectionClass"
  >
    <div
      class="container"
      :class="containerClass"
    >
      <slot />
    </div>
  </section>
</template>
